import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import UserHeader from 'components/user/UserHeader';

import {
  UserMenuContainer,
  UserMenuThumb,
} from 'components/user/UserMenu/UserMenu.styled';

const UserMenu = ({ closeModal, onOpenUserCabinet }) => {
  const [initialPage, setInitialPage] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    if (initialPage === pathname) {
      return;
    }

    if (initialPage === '') {
      setInitialPage(pathname);
    }

    if (initialPage !== '' && initialPage !== pathname) {
      closeModal();
    }
  }, [closeModal, initialPage, pathname]);

  return (
    <UserMenuContainer>
      <UserMenuThumb>
        <UserHeader
          orientation={'left'}
          pageType={'menu'}
          onOpenUserCabinet={onOpenUserCabinet}
        />
      </UserMenuThumb>
    </UserMenuContainer>
  );
};

export default UserMenu;
