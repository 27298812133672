import icon from 'media/images/svgSprite.svg';
import { LogoIcon, LogoLink } from 'components/common/Logo/Logo.styled';

const Logo = ({ iconName }) => {
  return (
    <LogoLink to="/">
      <LogoIcon>
        <use href={`${icon}#icon-${iconName}`}></use>
      </LogoIcon>
    </LogoLink>
  );
};

export default Logo;
