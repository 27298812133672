import styled, { keyframes } from 'styled-components';
import { vars } from 'utils/variables';

const openInput = keyframes`
  0% {
    width:  45px;
  }
  100% {
    width:  195px;
  }
`;

const orientation = { left: 'flex-start', center: 'center' };

export const UserMenuHeadWrap = styled.section`
  width: 100%;

  padding: 16px 24px;
  padding-bottom: ${props =>
    props.$pageType === 'user-cabinet' ? '0px' : '16px'};

  display: flex;
  align-items: center;
  justify-content: ${props => orientation[props.$orientation]};
  gap: 16px;

  background-color: ${props =>
    props.$pageType === 'user-cabinet' ? 'transparent' : vars.light50};
`;

export const UserIcon = styled.svg`
  width: 50px;
  height: 50px;

  fill: ${vars.dark70};
`;

export const UserNameWrap = styled.section`
  display: flex;
  align-items: center;
  gap: 5px;

  & > h5 {
    min-height: 24px;
    color: ${vars.dark70};
  }
`;

export const UserMeta = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  color: ${vars.darkBlack100};
`;

export const UserNameInput = styled.input`
  min-width: 20px;
  width: 195px;
  height: 24px;

  padding-right: 0;

  background-color: transparent;
  outline: none;
  border: 1px solid ${vars.secondaryLightBlue};

  animation: 400ms ${openInput} ease-in-out;

  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
`;

export const EditIcon = styled.svg`
  width: ${props => (props.$type === 'pencil' ? '12px' : '14px')};
  height: ${props => (props.$type === 'pencil' ? '12px' : '14px')};
  fill: ${vars.dark70};
  cursor: pointer;

  transition: fill ${vars.transitionTimingFunction};

  &:hover,
  &:focus-visible {
    fill: ${props =>
      props.$type === 'pencil' ? vars.secondaryBlue : vars.secondaryGreenDark};
  }
`;

export const UserMail = styled.p`
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
`;

export const UserCabinetLink = styled.button`
  color: ${vars.secondaryBlueHover};
  background-color: transparent;

  font-size: 11px;
  font-weight: 400;
  line-height: normal;

  &:hover {
    text-decoration-line: underline;
  }
`;
