import styled from 'styled-components';
import { vars } from 'utils/variables';
import { NavLink } from 'react-router-dom';

export const HeaderWrap = styled.header`
  width: 100%;
  height: 70px;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${vars.light100};
  box-shadow: ${vars.boxShadow};
  z-index: 10000;
`;

export const HeaderContainer = styled.nav`
  width: 100%;
  height: 100%;

  padding: 0px 16px;

  display: flex;
  align-items: center;

  @media screen and (min-width: 768px) {
    padding: 0px 28px;
  }

  @media screen and (min-width: 992px) {
    padding: 0px 16px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0px 40px;
  }
`;

export const BurgerIconWrap = styled.div`
  width: 28px;
  height: 28px;
`;

export const HeaderLogoSection = styled.div`
  margin-right: 82px;
  margin-left: 12px;

  position: relative;

  @media screen and (min-width: 768px) {
    margin-left: 20px;
  }
`;

export const BigLogoWrap = styled.div`
  width: 152px;
  height: 52px;
`;

export const SmallLogoWrap = styled.div`
  width: 44px;
  height: 47px;
`;

//---------------------------------------

export const NavWrap = styled.div`
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const MenuIcon = styled.svg`
  width: 100%;
  height: 100%;

  fill: ${vars.darkBlack100};
  cursor: pointer;
`;

export const CreditsWrap = styled(NavLink)`
  max-width: 194px;
  margin-right: 16px;
`;

export const CreditsText = styled.p`
  font-size: 16px;
  font-weight: 500;

  color: ${vars.darkBlack100};
`;

export const Progress = styled.div`
  height: 10px;
  margin-top: 8px;

  position: relative;

  background-color: ${vars.darkTransparent5};
  border-radius: 5px;
  overflow: hidden;

  &::before {
    content: '';
    width: ${props => (props.$used ? `calc(${props.$used} * 1%)` : '7%')};
    height: 100%;
    min-width: 1%;
    max-width: 100%;

    position: absolute;
    top: 0;
    left: 0;

    background-color: ${vars.primaryRose};
    border-radius: 5px;
  }
`;

export const UserIcon = styled.div`
  width: 40px;
  height: 40px;

  border-radius: 50%;
  overflow: hidden;
`;
