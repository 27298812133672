import styled from 'styled-components';
import { vars } from 'utils/variables';

export const CabinetContainer = styled.section`
  width: 100%;
  height: 100%;
  padding: 30px 0;

  z-index: 1001;
`;

export const UserCabinetWrap = styled.section`
  width: 100%;
  height: 100%;

  padding: 24px;

  position: relative;

  background-color: ${vars.light100};
  border-radius: ${vars.mainBorderRadius};

  overflow-x: hidden;
  white-space: nowrap;
  scrollbar-width: none;
`;

export const IconClose = styled.svg`
  width: 32px;
  height: 32px;

  position: absolute;
  top: 24px;
  right: 24px;
  fill: ${vars.dark70};
  cursor: pointer;

  transition: opacity ${vars.transitionTimingFunction},
    fill ${vars.transitionTimingFunction};

  &:hover {
    fill: ${vars.primaryPrimary};
  }

  @media screen and (min-width: 768px) {
    top: 16px;
    right: 16px;
  }
`;

export const UserHeaderWrap = styled.section`
  margin-left: -24px;
`;

export const SectionBody = styled.section`
  width: 100%;
  max-height: ${props => (props.$active ? 'auto ' : '0')};
  margin-top: ${props => (props.$active ? '24px' : '0')};

  overflow: hidden;
  color: ${vars.darkBlack100};

  transition: max-height ${vars.transitionTimingFunction},
    margin ${vars.transitionTimingFunction};

  & .active {
    max-height: 2rem;
    margin: 1rem 0;
  }
`;
