import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { BASE_URL, API__PREFIX, API__VERSION } from 'utils/configs';

import handleError from 'services/handleError';

axios.defaults.baseURL = `${BASE_URL}`;
axios.defaults.withCredentials = true;

export const getUserData = createAsyncThunk(
  'userdata/getUserData',

  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${API__PREFIX}${API__VERSION}/user/profile/read`
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const changeUserData = createAsyncThunk(
  'userdata/changeUserData',

  async (userData, thunkAPI) => {
    try {
      await axios.post(
        `${API__PREFIX}${API__VERSION}/user/profile/update`,
        userData
      );

      return userData;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);
