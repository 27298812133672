import styled, { keyframes } from 'styled-components';

const petalsAnimation = keyframes`
  5% {
    opacity: 1;
    animation-timing-function: ease-out;
  }

  30% {
    opacity: 0;
  }

  55% {
    opacity: 0;
    animation-timing-function: ease-in;
    
  }

 80% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  width: 34px;
  height: 34px;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  @media screen and (min-width: 768px) {
    width: 62px;
    height: 62px;
  }
`;

export const LoaderWrap = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const LoaderIcon = styled.svg`
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
`;

export const LoaderIconPetal = styled(LoaderIcon)`
  animation: ${props => `${props.$animationTime}ms`} ${petalsAnimation} infinite;
  animation-play-state: running;
  animation-delay: ${props => `${props.$disappearance}ms`};
`;
