import styled from 'styled-components';
import { vars } from 'utils/variables';

export const UserMenuContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
`;

export const UserMenuThumb = styled.section`
  width: 328px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: absolute;
  right: 16px;
  top: 70px;

  background-color: ${vars.light100};

  border-bottom-left-radius: ${vars.mainBorderRadius};
  border-bottom-right-radius: ${vars.mainBorderRadius};
  box-shadow: ${vars.boxShadow};
  overflow: hidden;

  @media screen and (min-width: 768px) {
    right: 28px;
  }

  @media screen and (min-width: 992px) {
    right: 16px;
  }

  @media screen and (min-width: 1200px) {
    right: 40px;
  }
`;
