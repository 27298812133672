import styled from 'styled-components';
import { vars } from 'utils/variables';

export const CreditsWrap = styled.section`
  max-width: 194px;
`;

export const CreditsText = styled.p`
  font-size: 16px;
  font-weight: 500;

  color: ${vars.darkBlack100};
`;

export const Progress = styled.div`
  height: 10px;
  margin-top: 8px;

  position: relative;

  background-color: ${vars.darkTransparent5};
  border-radius: 5px;

  &::before {
    content: '';
    width: ${props => (props.$used ? `calc(${props.$used} * 1%)` : '7%')};
    height: 100%;
    min-width: 1%;
    max-width: 100%;

    position: absolute;
    top: 0;
    left: 0;

    background-color: ${vars.primaryRose};
    border-radius: 5px;
  }
`;

export const ButtonText = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;

  @media screen and (min-width: 768px) {
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
  }
`;

export const PlanDescription = styled.ul`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const DescriptionItem = styled.li`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;

  & > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
`;

export const ItemTitle = styled.h5`
  width: 100%;
  color: ${vars.secondaryBlue};

  @media screen and (min-width: 768px) {
    width: 260px;
  }

  @media screen and (min-width: 1200px) {
    width: 350px;
  }
`;

//----------plan selection----------------

export const PlanSelection = styled.section`
  margin-top: 24px;
`;

export const BlockTitle = styled.h5`
  text-transform: uppercase;
`;

export const PlanList = styled.ul`
  margin: 24px 0;

  display: flex;
  flex-wrap: wrap;
  border: none;
  border-radius: 16px;
  border: 1px solid ${vars.darkBlack100};
  overflow: hidden;

  @media screen and (min-width: 768px) {
    display: inline-flex;
    flex-wrap: nowrap;
  }
`;

export const ListItem = styled.li`
  width: 50%;
  height: 40px;
  padding: 0 8px;
  position: relative;

  background-color: ${props =>
    props.$active ? vars.secondaryBlue : 'transparent'};
  border-color: ${vars.darkBlack100};
  text-align: center;
  overflow: hidden;

  &:nth-child(1) {
    border-right: 1px solid;
    border-bottom: 1px solid;
  }

  &:nth-child(2) {
    border-bottom: 1px solid;
  }

  &:nth-child(3) {
    border-right: 1px solid;
  }

  &:hover > button,
  &:active > button {
    transform: translateY(0%);
  }

  @media screen and (min-width: 768px) {
    width: auto;
    min-width: 120px;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border: none;
    }
  }
`;

export const PlanButton = styled.button`
  width: 100%;
  height: 100%;
  padding: 0;

  background-color: transparent;
  color: ${props => (props.$active ? vars.light100 : vars.darkBlack100)};

  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

export const PlanInfo = styled.button`
  width: 100%;
  height: 100%;
  padding: 0 8px;
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${vars.secondaryBlueHover};
  transform: translateY(101%);

  transition: transform ${vars.transitionTimingFunction};

  & > p {
    color: ${vars.light100};
    font-size: 11px;
    line-height: normal;
    text-align: center;
    text-transform: initial;
    white-space: wrap;
  }
`;
