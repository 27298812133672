export const TITLES = {
  pageTitle: {
    aiPhotoshooting: {
      aiPhotoshootingTitle: 'AI Photo Shooting',
      createStepOne: '1. Choose model avatar',
      createStepTwo: '2. Upload cloth image',
      otherPages: 'AI Photo Shooting',
    },
    aiGenerateModels: {
      aiGenerateModelsTitle: 'AI Generate Models',
      createStepOne: '1. Choose model avatar',
      createStepTwo: '2. Upload your model image',
    },
    backgroundRemove: 'Background Remove',
    imageUpscaling: 'Image Upscaling',
    pricing: 'Pricing',
    fashionStore: {
      FSTitle: 'AI Fashion Store',
      FSDescriptionTitle: 'Title:',
      FSDescriptionText: 'Product Description:',
      FSProductInfo: 'Product Info:',
    },
    demo: 'Demo',
    notFound: 'Error 404',
  },
  pageSubtitle: {
    gallery: 'Transfer Tasks',
    details: 'Task Details',
    createBgRemove: 'Pick an image to remove the background',
    createImgUpscaling: 'Upscale image to your composition',
    pricing: 'For custom pricing, please contact us',
    fashionStore: 'Create order and add your images.',
    notFound: 'Page Not Found',
  },
  buttonTitle: {
    addImagesTitle: 'Add images',
    addImagesSubtitle: 'Up to 10 images, max 5 MB each',
    addGalleryImagesSubtitle: 'Up to 10 images',
    addOwnImages: 'Upload from your device',
    addGalleryImages: 'Paste from Mod-y gallery',
    save: 'Save',
    finishOrder: 'Finish',
    publishOrder: 'Publish',
    fashionStore: {
      generateDescription: 'AI Generate description',
      generateText: 'Generate',
      completeOrder: 'Complete',
      editDescription: 'edit',
      saveDescription: 'save',
      saveDisabledTip: 'Please fill in all fields.',
      dropdown1: 'Select material',
      dropdown2: 'Clothes Type',
      goToDetails: 'Details',
      deleteOrders: 'Delete Order',
    },
  },
  paths: {
    toFSGallery: 'Your Orders',
    toBGGallery: 'Background Remove Tasks',
    toFSNewOrder: 'New Order',
    toFSOrder: 'Order',
    toFSOrderPreview: 'Order Preview',
    globalGallery: 'Global Gallery',
  },
  questions: { deleteApproval: 'Are you sure you want to delete it?' },
};

export const AUTH_META = {
  introTitle: 'AI in fashion',
  introText:
    'Mod-y lets you add AI for the monotonous process of buying clothes for different styles, as well as placing clothing content on models, in different poses, and text content on online websites through integration with the marketplace and E-commerce',
  introButtonText: 'Watch Demo',
  introLinkText: 'Schedule a Call',
};

export const MESSAGES = {
  tooManyImagesTitle: 'You are trying to add more than 10 images in total.',
  tooManyImagesText: 'You can add ',
  tooLargeImagesTitle: `Add pictures up to 5 MB each`,
  tooLargeImagesText: `Some of the selected images were too large.
    Images larger than 5 MB were excluded from the list.`,
  notMatchImgFormatTitle: `Add PNG or JPEG images`,
  notMatchImgFormatText: `Allowed image formats are .png and .jpg.
    Pictures with other formats were not added to the list.`,
  maxImagesNumberTitle: `You've added the maximum number of images`,
  maxImagesNumberText: ``,
  noAvatars: 'There are no models with the selected parameters',
  askToSelecteAvatar: 'Please, choose model avatar.',
  noTasks: 'There are no tasks here yet.',
  noImageResults: 'There are no image results.',
  dataNotFound: 'Data not found. Please try again',
  serverError: 'Something went wrong. Please reload the page',
  betaLable: [
    'User is responsible for verifying and validating AI generated images.',
    'Image generation results may vary as AI algorithms improve every month.',
    'Please also inform us about possible difficulties in the chat',
  ],
};

export const ALLOWED_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/JPEG',
  'image/JPG',
  'image/PNG',
];

export const SIZES = {
  small: 'XS-S',
  medium: 'M',
  large: 'L-XL',
  smallImage: '70x70',
  largeImage: '400x450',
};

export const AVATAR_SIZES = ['XS-S', 'M', 'L-XL'];

export const MATERIAL_TYPES = [
  'Cotton',
  'Viscose',
  'Linen',
  'Polyester',
  'Atlas',
  'Silk',
];

export const CLOTHES_TYPES = ['T-shirt', 'Dress', 'Pants', 'Jeans', 'Skirt'];

export const AVATAR_CLOTH_TYPES = ['upper_body', 'lower_body', 'dress'];

export const AVATAR_MATCHING_CLOTH_TYPES_TO_NAMES = {
  upper_body: 'Upper',
  lower_body: 'Lower',
  dress: 'Full',
};

export const MODAL_META = {
  success: {
    title: 'Congratulations!',
    addedImagesTitle: 'Great!',
    message: 'Your images are in processing',
    onCreateOrder: 'Your order is created',
    messageForOrder: 'Images added successfully',
    buttonText: 'Close',
    buttonTextForOrder: 'Continue',
  },
  error: {
    title: 'Error',
    message: 'Sorry, something went wrong, please try again.',
    imageError:
      'Parameters of some images are not appropriate for processing. Allowed: formats png/jpg, max size - 5 MB, max resolution - 5000px',
    buttonText: 'Try again',
  },
  attention: {
    title: 'Attention!',
    message: 'Please, check the previous steps.',
    buttonText: 'Go back',
  },
  assess: {
    question: 'How would you evaluate the result?',
    buttonText: 'Send',
  },
  gratitude: {
    feedback: 'Thank you for your feedback',
  },
  aiGenerateDescription: {
    title: 'Please, choose the material and clothes type.',
    buttonText: 'AI Generate description',
  },
  aboutOwnFiles: {
    title: 'The task must contain images of the same type.',
    message:
      'Click "Continue" to create a task with images from the Mod-y gallery, or "Go back" to use images from your device.',
  },
};

export const PRICE_LIST = ['Pro', 'Business', 'Enterprise'];

export const PRICE_META = {
  tariffs: [
    {
      planName: 'Basic',
      price: 5,
      credits: 4,
      benefits: [
        'AI Photoshooting',
        'Image Upscaling',
        'Background Remove',
        'AI Fashion Store',
      ],
      buttonText: 'Try Now',
      infoText:
        '1 Credit is 1 image generation. Credits do not accumulate after the end of 30 days after payment is made.',
    },
    {
      planName: 'Pro',
      price: 30,
      credits: 30,
      benefits: [
        'AI Photoshooting',
        'Image Upscaling',
        'Background Remove',
        'AI Fashion Store',
        'REST API for integration',
        '1 year Images Retention',
        'Support',
      ],
      buttonText: 'Buy Now',
      infoText:
        '1 Credit is 1 image generation. Credits do not accumulate after the end of 30 days after payment is made.',
    },
    {
      planName: 'Business',
      price: 100,
      credits: 130,
      benefits: [
        // 'Custom Development',
        'AI Photoshooting',
        'Image Upscaling',
        'Background Remove',
        'AI Fashion Store',
        {
          benefitIcon: 'shopify',
          text: 'Integration by request',
          linkMeta: { text: 'Shopify', link: 'https://apps.shopify.com/' },
        },
        'REST API for integration',
        '1 year Images Retention',
        'Support',
      ],
      buttonText: 'Buy Now',
      infoText:
        '1 Credit is 1 image generation. Credits do not accumulate after the end of 30 days after payment is made.',
    },
    {
      planName: 'Enterprise',
      price: 'Custom',
      credits: 'Unlimited',
      benefits: [
        'AI Photoshooting',
        'Image Upscaling',
        'Background Remove',
        'AI Fashion Store',
        {
          benefitIcon: 'shopify',
          text: 'Integration by request',
          linkMeta: { text: 'Shopify', link: 'www.shopify.com' },
        },
        'Custom Development by request',
        'REST API for integration',
        '2+ year Images Retention',
        'Support',
      ],
      buttonText: 'Request a Quote',
      infoText:
        '1 Credit is 1 image generation. Credits do not accumulate after the end of 30 days after payment is made.',
    },
  ],

  tables: {
    price: {
      title: '',
      services: [
        { id: 'pricing1', text: 'Price' },
        { id: 'pricing2', text: 'Credits' },
        { id: 'pricing3', text: 'Pricing model' },
        { id: 'pricing4', text: 'Hosting' },
        { id: 'pricing5', text: 'REST API for integration' },
        { id: 'pricing6', text: 'Images retention' },
      ],
      plan1: {
        title: PRICE_LIST[0],
        services: [
          { id: 'bundle11', text: '30 USD' },
          { id: 'bundle12', text: '30' },
          { id: 'bundle13', text: 'Pay-as-You-Go' },
          { id: 'bundle14', text: 'SaaS cloud' },
          { id: 'bundle15', text: 'Yes' },
          { id: 'bundle16', text: '1 year' },
        ],
      },
      plan2: {
        title: PRICE_LIST[1],
        services: [
          { id: 'bundle21', text: '100 USD' },
          { id: 'bundle22', text: '130' },
          { id: 'bundle23', text: 'Pay-as-You-Go' },
          { id: 'bundle24', text: 'SaaS cloud' },
          { id: 'bundle25', text: 'Yes' },
          { id: 'bundle26', text: '1 year' },
        ],
      },
      plan3: {
        title: PRICE_LIST[2],
        services: [
          { id: 'bundle31', text: 'Custom' },
          { id: 'bundle32', text: 'Unlimited' },
          { id: 'bundle33', text: 'Pay-as-You-Go' },
          { id: 'bundle34', text: 'SaaS cloud, Private cloud, or On-prem' },
          { id: 'bundle35', text: 'Yes' },
          { id: 'bundle36', text: 'more 2 year' },
        ],
      },
    },
    featurePlan: {
      title: 'Feature plan',
      services: [
        { id: 'feature1', text: 'AI Photoshooting' },
        { id: 'feature2', text: 'Image Upscaling' },
        { id: 'feature3', text: 'Background remove' },
        { id: 'feature4', text: 'AI Fashion Store' },
        { id: 'feature5', text: 'AI Fashion Models' },
        // { id: 'feature6', text: 'Virtual Try-on' },
        // { id: 'feature7', text: 'AI-Powered Fashion Buying' },
        // { id: 'feature8', text: 'AI uplift models for Fashion sell' },
        // { id: 'feature9', text: 'AI Recommendation Systems' },
        // { id: 'feature10', text: 'AI Fashion Stylist' },
        // { id: 'feature11', text: 'AI Cloth Tagging' },
        // { id: 'feature12', text: 'AI Fashion Design 2D&3D' },
        // { id: 'feature13', text: 'AI Materail Fashion Tagging' },
        // { id: 'feature14', text: 'AI Garmet Tailoring and ROS2 Robotics' },
        {
          id: 'feature15',
          text: 'AI agents platform for sell and purchase products',
        },
        {
          id: 'feature16',
          text: 'AI core platform for custom AI models',
        },
      ],
      plan1: {
        title: PRICE_LIST[0],
        services: [
          { id: 'feature11', text: 'Yes' },
          { id: 'feature12', text: 'Yes' },
          { id: 'feature13', text: 'Yes' },
          { id: 'feature14', text: 'Yes' },
          { id: 'feature15', text: 'Getting soon' },
          // { id: 'feature16', text: '-' },
          // { id: 'feature17', text: '-' },
          // { id: 'feature18', text: '-' },
          // { id: 'feature19', text: '-' },
          // { id: 'feature110', text: '-' },
          // { id: 'feature111', text: '-' },
          // { id: 'feature112', text: '-' },
          // { id: 'feature113', text: '-' },
          // { id: 'feature114', text: '-' },
          { id: 'feature115', text: '-' },
          { id: 'feature116', text: '-' },
        ],
      },
      plan2: {
        title: PRICE_LIST[1],
        services: [
          { id: 'feature21', text: 'Yes' },
          { id: 'feature22', text: 'Yes' },
          { id: 'feature23', text: 'Yes' },
          { id: 'feature24', text: 'Yes' },
          { id: 'feature25', text: 'Getting soon' },
          // { id: 'feature26', text: '-' },
          // { id: 'feature27', text: '-' },
          // { id: 'feature28', text: '-' },
          // { id: 'feature29', text: '-' },
          // { id: 'feature210', text: '-' },
          // { id: 'feature211', text: '-' },
          // { id: 'feature212', text: '-' },
          // { id: 'feature213', text: '-' },
          // { id: 'feature214', text: '-' },
          { id: 'feature215', text: '-' },
          { id: 'feature216', text: '-' },
        ],
      },
      plan3: {
        title: PRICE_LIST[2],
        services: [
          { id: 'feature31', text: 'Yes' },
          { id: 'feature32', text: 'Yes' },
          { id: 'feature33', text: 'Yes' },
          { id: 'feature34', text: 'Yes' },
          { id: 'feature35', text: 'Getting soon' },
          // { id: 'feature36', text: 'Available by request' },
          // { id: 'feature37', text: 'Available by request' },
          // { id: 'feature38', text: 'Available by request' },
          // { id: 'feature39', text: 'Available by request' },
          // { id: 'feature310', text: 'Available by request' },
          // { id: 'feature311', text: 'Available by request' },
          // { id: 'feature312', text: 'Available by request' },
          // { id: 'feature313', text: 'Available by request' },
          // { id: 'feature314', text: 'Available by request' },
          { id: 'feature315', text: 'Available by request' },
          { id: 'feature316', text: 'Available by request' },
        ],
      },
    },
    integration: {
      title: 'Integration',
      services: [
        {
          id: 'integration1',
          text: {
            link: 'www.shopify.com',
            text: 'Shopify',
            partnerIcon: 'shopify',
          },
        },
        { id: 'integration2', text: 'Third party e-commerce website' },
      ],
      plan1: {
        title: PRICE_LIST[0],
        services: [
          { id: 'integration11', text: 'Getting soon' },
          { id: 'integration12', text: '-' },
        ],
      },
      plan2: {
        title: PRICE_LIST[1],
        services: [
          { id: 'integration21', text: 'Getting soon' },
          { id: 'integration22', text: '-' },
        ],
      },
      plan3: {
        title: PRICE_LIST[2],
        services: [
          { id: 'integration31', text: 'Getting soon' },
          { id: 'integration32', text: 'Available by request' },
        ],
      },
    },
    customizationAndSupport: {
      title: 'Customization and support',
      services: [
        { id: 'customizationAndSupport1', text: 'Fashion Models' },
        { id: 'customizationAndSupport2', text: 'Custom development' },
        {
          id: 'customizationAndSupport3',
          text: 'Custom AI developent and consulting',
        },
        { id: 'customizationAndSupport4', text: 'AI models updates' },
        { id: 'customizationAndSupport5', text: 'Support' },
      ],
      plan1: {
        title: PRICE_LIST[0],
        services: [
          { id: 'customizationAndSupport11', text: 'Standart' },
          { id: 'customizationAndSupport12', text: '-' },
          { id: 'customizationAndSupport13', text: '-' },
          { id: 'customizationAndSupport14', text: 'Yes' },
          { id: 'customizationAndSupport15', text: 'Community support' },
        ],
      },
      plan2: {
        title: PRICE_LIST[1],
        services: [
          { id: 'customizationAndSupport21', text: 'Standart' },
          { id: 'customizationAndSupport22', text: '-' },
          { id: 'customizationAndSupport23', text: '-' },
          { id: 'customizationAndSupport24', text: 'Yes' },
          { id: 'customizationAndSupport25', text: 'Available by request' },
        ],
      },
      plan3: {
        title: PRICE_LIST[2],
        services: [
          { id: 'customizationAndSupport31', text: 'Standart and Custom' },
          {
            id: 'customizationAndSupport32',
            text: 'Included within support hours and available by request',
          },
          { id: 'customizationAndSupport33', text: 'Available by request' },
          { id: 'customizationAndSupport34', text: 'Yes' },
          {
            id: 'customizationAndSupport35',
            text: 'Included up to 10 hours per month',
          },
        ],
      },
    },
  },

  footnotes: [
    { id: 'footnotes1', text: '1 Credit is 1 image generation' },
    {
      id: 'footnotes2',
      text: 'Credits do not accumulate after the end of 30 days after payment is made',
    },
    {
      id: 'footnotes3',
      text: 'Algorithm updates for on-prem and private cloud available by request',
    },
    {
      id: 'footnotes4',
      text: 'All images uploaded to the SaaS could be used for AI training for improvement algorithms but not for public distribution. Please read the privacy policy.',
    },
  ],
};

export const VIDEO_META = {
  subtitle: 'Create your image in a few clicks',
  subtitleFS: 'Create your order in a few clicks',
};
