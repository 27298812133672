import { Amplify } from 'aws-amplify';
import { Authenticator, View, Image } from '@aws-amplify/ui-react';

import { lazy, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';

import {
  MEETING_LINK,
  USER_POOL_ID,
  USER_POOL_CLIENT_ID,
  USER_POOL_DOMAIN,
  USER_POOL_SIGN_IN_URL,
  USER_POOL_SIGN_OUT_URL,
} from 'utils/configs';
import { AUTH_META } from 'utils/constants';

import PrivateRoute from 'components/PrivateRoute';
import SignInUp from 'pages/auth/SignInUp/SignInUp';
import SharedLayout from 'components/SharedLayout/SharedLayout';
import Modal from 'components/common/modals/Modal';
import WelcomeVideo from 'components/WelcomeVideo';
import logo from 'media/images/logo_orgn.svg';

const CreatePage = lazy(() => import('pages/ai-photoshooting/CreatePage'));
const GalleryPage = lazy(() => import('pages/ai-photoshooting/GalleryPage'));
const TaskDetailsPage = lazy(() =>
  import('pages/ai-photoshooting/TaskDetailsPage')
);

const ModelsCreatePage = lazy(() =>
  import('pages/ai-generate-models/ModelsCreatePage')
);
const ModelsGalleryPage = lazy(() =>
  import('pages/ai-generate-models/ModelsGalleryPage')
);
const ModelsTaskDetailsPage = lazy(() =>
  import('pages/ai-generate-models/ModelsTaskDetailsPage')
);

const BgRemoveCreatePage = lazy(() =>
  import('pages/bg-remove/BgRemoveCreatePage')
);
const BgRemoveGalleryPage = lazy(() =>
  import('pages/bg-remove/BgRemoveGalleryPage')
);
const BgRemoveTaskDetailsPage = lazy(() =>
  import('pages/bg-remove/BgRemoveTaskDetailsPage')
);

const ImageUpscalingCreatePage = lazy(() =>
  import('pages/image-upscaling/ImageUpscalingCreatePage')
);
const ImageUpscalingGalleryPage = lazy(() =>
  import('pages/image-upscaling/ImageUpscalingGalleryPage')
);
const ImageUpscalingTaskDetailsPage = lazy(() =>
  import('pages/image-upscaling/ImageUpscalingTaskDetailsPage')
);

const PricePage = lazy(() => import('pages/PricePage'));
const Contacts = lazy(() => import('pages/Contacts'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage'));

const FashionStoreCreatePage = lazy(() =>
  import('pages/fashion-store/FashionStoreCreatePage')
);
const FashionStoreGalleryPage = lazy(() =>
  import('pages/fashion-store/FashionStoreGalleryPage')
);
const FashionStoreOrderDetailsPage = lazy(() =>
  import('pages/fashion-store/FashionStoreOrderDetailsPage')
);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: USER_POOL_ID,
      userPoolClientId: USER_POOL_CLIENT_ID,
      loginWith: {
        username: true,
        oauth: {
          domain: USER_POOL_DOMAIN,
          scopes: ['email', 'openid'],
          redirectSignIn: [USER_POOL_SIGN_IN_URL],
          redirectSignOut: [USER_POOL_SIGN_OUT_URL],
          responseType: 'code',
          providers: ['Google'],
        },
      },
      userAttributes: {
        email: {
          required: true,
        },
      },
    },
  },
});

function App() {
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);

  const components = {
    Header() {
      return (
        <View className="auth-logo">
          <Image alt="Mod-y logo" src={logo} height={38} />
        </View>
      );
    },

    Footer() {
      return (
        <View className="auth-welcome-info">
          <section className="auth-welcome-text-wrap">
            <h1 className="auth-welcome-title">{AUTH_META.introTitle}</h1>
            <p className="auth-welcome-text">{AUTH_META.introText}</p>
            <div className="auth-welcome-interaction">
              <button
                type="button"
                className="auth-welcome-btn"
                onClick={() => setIsWelcomeModalOpen(true)}
              >
                {AUTH_META.introButtonText}
              </button>
              <a
                href={MEETING_LINK}
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="Company Terms"
                className="auth-welcome-link"
              >
                {AUTH_META.introLinkText}
              </a>
            </div>
          </section>

          {isWelcomeModalOpen && (
            <Modal closeModal={() => setIsWelcomeModalOpen(false)}>
              <WelcomeVideo
                onCloseWelcomeModal={() => setIsWelcomeModalOpen(false)}
              />
            </Modal>
          )}
        </View>
      );
    },

    SignUp: {
      FormFields() {
        return (
          <>
            <Authenticator.SignUp.FormFields />

            <p className="agree-text">
              * By signing up, you agree to our{' '}
              <a
                href="https://www.mod-y.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="Company Terms"
                className="agree-link"
              >
                Terms
              </a>{' '}
              of Use and acknowledge you've read our{' '}
              <a
                href="https://www.mod-y.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="Company Privacy Policy"
                className="agree-link"
              >
                Privacy Policy
              </a>
            </p>
          </>
        );
      },
    },
  };

  return (
    <Authenticator components={components}>
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route
            index
            element={
              <PrivateRoute>
                <FashionStoreGalleryPage />
              </PrivateRoute>
            }
          />

          <Route path="signin" element={<SignInUp />} />

          <Route
            path="ai-photoshooting"
            element={
              <PrivateRoute>
                <CreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-photoshooting/gallery"
            element={
              <PrivateRoute>
                <GalleryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-photoshooting/details/:taskId"
            element={
              <PrivateRoute>
                <TaskDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="ai-generate-models"
            element={
              <PrivateRoute>
                <ModelsCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-generate-models/gallery"
            element={
              <PrivateRoute>
                <ModelsGalleryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-generate-models/details/:taskId"
            element={
              <PrivateRoute>
                <ModelsTaskDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="bg-remove"
            element={
              <PrivateRoute>
                <BgRemoveCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="bg-remove/gallery"
            element={
              <PrivateRoute>
                <BgRemoveGalleryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="bg-remove/details/:taskId"
            element={
              <PrivateRoute>
                <BgRemoveTaskDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="image-upscaling"
            element={
              <PrivateRoute>
                <ImageUpscalingCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="image-upscaling/gallery"
            element={
              <PrivateRoute>
                <ImageUpscalingGalleryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="image-upscaling/details/:taskId"
            element={
              <PrivateRoute>
                <ImageUpscalingTaskDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="pricing"
            element={
              <PrivateRoute>
                <PricePage />
              </PrivateRoute>
            }
          />
          <Route
            path="help-and-contacts"
            element={
              <PrivateRoute>
                <Contacts />
              </PrivateRoute>
            }
          />

          <Route
            path="ai-fashion-store"
            element={
              <PrivateRoute>
                <FashionStoreCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-fashion-store/gallery"
            element={
              <PrivateRoute>
                <FashionStoreGalleryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-fashion-store/order/:orderId"
            element={
              <PrivateRoute>
                <FashionStoreOrderDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="*"
            element={
              <PrivateRoute>
                <NotFoundPage />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </Authenticator>
  );
}

export default App;
