import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { vars } from 'utils/variables';

export const FooterWrap = styled.footer`
  width: 100%;

  margin-top: 32px;

  background-color: ${vars.light100};

  @media screen and (min-width: 768px) {
    margin-top: 90px;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 32px 16px;

  margin: 0 auto;

  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    padding: 32px 28px;
    align-items: flex-start;
  }

  @media screen and (min-width: 992px) {
    padding: 32px 16px;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (min-width: 1200px) {
    padding: 32px 40px;
  }
`;

export const FooterLogoWrap = styled.div`
  width: 76px;
  height: 26px;

  position: relative;
`;

export const CopyrightRights = styled.p`
  width: 244px;

  color: ${vars.darkBlack100};

  font-size: 11px;
  font-weight: 400;

  @media screen and (min-width: 992px) {
    margin-top: 12px;
    margin-right: 100px;
  }

  @media screen and (min-width: 1200px) {
    margin-right: 212px;
  }
`;

export const CopyrightLink = styled.a`
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

export const FooterMenu = styled.section`
  max-width: 900px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  @media screen and (min-width: 1200px) {
    gap: 24px;
  }
`;

export const FooterSection = styled.section`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  & h5 {
    margin-bottom: 12px;
    line-height: normal;
  }

  @media screen and (min-width: 768px) {
    width: 130px;
    align-items: flex-start;
  }

  @media screen and (min-width: 992px) {
    width: 140px;
  }

  @media screen and (min-width: 1200px) {
    width: 147px;
  }
`;

export const FooterLink = styled(NavLink)`
  margin-bottom: 6px;

  color: ${vars.primaryHover};

  font-size: 11px;
  font-weight: 500;
  line-height: normal;

  &:hover {
    text-decoration: underline;
  }
`;

export const TutorialLink = styled.button`
  margin-bottom: 6px;
  padding: 0;

  background-color: transparent;
  color: ${vars.primaryHover};

  font-size: 11px;
  font-weight: 500;
  line-height: normal;

  &:hover {
    text-decoration: underline;
  }
`;

export const FooterPolicyAndTermsLink = styled.a`
  margin-bottom: 6px;

  color: ${vars.primaryHover};

  font-size: 11px;
  font-weight: 500;
  line-height: normal;

  &:hover {
    text-decoration: underline;
  }
`;

export const FooterSocLink = styled.a`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 8px;
  }
  padding: 0;

  border-radius: 50%;
  line-height: 0.5;
`;

export const FooterSocIcon = styled.svg`
  width: 28px;
  height: 28px;

  fill: ${vars.primaryHover};

  border-radius: 50%;

  transition: fill ${vars.transitionTimingFunction};

  &:hover {
    fill: ${vars.primaryPrimary};
  }
`;
