import styled from 'styled-components';
import { vars } from 'utils/variables';

export const SectionWrap = styled.li`
  width: 100%;
  padding-top: ${props => (props.$active ? '20px' : ' 12px')};
  padding-bottom: ${props => (props.$active ? '20px' : ' 12px')};
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  border-radius: 24px;
  border: 1px solid ${vars.darkBlack100};

  transition: padding ${vars.transitionTimingFunction};

  @media screen and (min-width: 768px) {
    padding-top: ${props => (props.$active ? '24px' : ' 12px')};
    padding-bottom: ${props => (props.$active ? '24px' : ' 12px')};
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const ContentWrap = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const SectionTitle = styled.h2`
  color: ${vars.darkBlack100};
`;

export const ArrowIcon = styled.svg`
  width: 16px;
  height: 16px;

  fill: ${vars.dark70};
  transform: ${props =>
    props.$active ? 'rotate(-270deg)' : ' rotate(-90deg)'};
  transition: transform ${vars.transitionTimingFunction};
`;
