import { MESSAGES } from 'utils/constants';
import icon from 'media/images/svgSprite.svg';

import {
  BetaLableWrap,
  BetaIcon,
  BetaMessage,
} from 'components/common/BetaLable/BetaLable.styled';

const BetaLable = ({
  topLablePos,
  rightLablePos,
  topMessagePos,
  bottomMessagePos,
  leftMessagePos,
  translateXValue,
  lableLocation,
}) => {
  return (
    <>
      <BetaLableWrap
        type="button"
        $top={topLablePos}
        $right={rightLablePos}
        $lableLocation={lableLocation}
        onClick={e => e.stopPropagation()}
      >
        <BetaIcon $lableLocation={lableLocation}>
          <use href={`${icon}#icon-beta`}></use>
        </BetaIcon>
      </BetaLableWrap>
      <BetaMessage
        $top={topMessagePos}
        $bottom={bottomMessagePos}
        $left={leftMessagePos}
        $translateX={translateXValue}
      >
        {MESSAGES.betaLable.map(item => {
          return <p key={item}>{item}</p>;
        })}
      </BetaMessage>
    </>
  );
};

export default BetaLable;
