import styled, { keyframes } from 'styled-components';
import { vars } from 'utils/variables';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const WelcomeSection = styled.section`
  width: 100%;
  padding: 24px 20px;
  position: relative;

  background-color: ${vars.light100};
  box-shadow: ${vars.modalBoxShadow};

  text-align: center;

  @media screen and (min-width: 768px) {
    width: 720px;
    padding: 30px;
    border-radius: ${vars.mainBorderRadius};
    text-align: left;
  }
`;

export const ModalIconClose = styled.svg`
  width: 24px;
  height: 24px;

  position: absolute;
  top: 24px;
  right: 20px;
  fill: ${vars.dark70};
  cursor: pointer;

  transition: opacity ${vars.transitionTimingFunction},
    fill ${vars.transitionTimingFunction};

  &:hover {
    fill: ${vars.primaryHover};
  }

  @media screen and (min-width: 768px) {
    top: 30px;
    right: 30px;
  }
`;

export const VideoWrap = styled.section`
  width: 320px;
  height: 180px;

  margin: 20px auto 0 auto;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${vars.darkBlack100};

  border-radius: 8px;
  border: none;
  outline: none;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  &:hover > svg {
    width: 79px;
    height: 79px;
    fill: ${vars.primaryHover};

    @media screen and (min-width: 768px) {
      width: 161px;
      height: 161px;
    }
  }

  &:active > svg {
    width: 75px;
    height: 75px;
    fill: ${vars.primaryPrimary};

    @media screen and (min-width: 768px) {
      width: 157px;
      height: 157px;
    }
  }

  @media screen and (min-width: 768px) {
    width: 660px;
    height: 371px;
    margin-top: 24px;
    border-radius: 16px;
  }
`;

export const PlayIcon = styled.svg`
  width: 77px;
  height: 77px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  fill: ${vars.primaryPrimary};
  cursor: pointer;

  transition: fill ${vars.transitionTimingFunction},
    width ${vars.transitionTimingFunction},
    height ${vars.transitionTimingFunction};
  animation: 500ms ${fadeIn} ease;

  @media screen and (min-width: 768px) {
    width: 159px;
    height: 159px;
  }
`;
