import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { vars } from 'utils/variables';

export const LogoLink = styled(NavLink)`
  height: 100%;
  display: inline-block;

  &:focus {
    transform: scale(1.01);
  }
`;

export const LogoIcon = styled.svg`
  width: 100%;
  height: 100%;

  fill: transparent;
  cursor: pointer;

  transition: transform ${vars.transitionTimingFunction};
`;
