import { Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

const PrivateRoute = ({ children }) => {
  const { route } = useAuthenticator(context => [context.route]);

  return route === 'authenticated' ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
