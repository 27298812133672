import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  selectCurrentCreditsNumber,
  selectPlanName,
  selectSubscriptionCreatedDate,
  selectSubscriptionExpirationDate,
  selectPlanCreditsNumber,
  selectPlanStatus,
} from '../../../redux/user-data/selectors';

import { ButtonByContent } from 'components/common/Buttons/Buttons';

import {
  CreditsWrap,
  CreditsText,
  // Progress,
  ButtonText,
  PlanDescription,
  DescriptionItem,
  ItemTitle,
} from 'components/user/Balance/Balance.styled';

const Balance = ({ closeModal }) => {
  const [currentPlan, setCurrentPlan] = useState('-');
  const [expirationDate, setExpirationDate] = useState('-');
  const [paymentDate, setPaymentDate] = useState('-');
  const [balance, setBalance] = useState({
    current: 0,
    maxPossible: 0,
  });
  // const [usagePercentage, setUsagePercentage] = useState(0);

  const subscriptionStatus = useSelector(selectPlanStatus);
  const plan = useSelector(selectPlanName);
  const credits = useSelector(selectCurrentCreditsNumber);
  const planCredits = useSelector(selectPlanCreditsNumber);
  const subscriptionCreatedDate = useSelector(selectSubscriptionCreatedDate);
  const subscriptionExpirationDate = useSelector(
    selectSubscriptionExpirationDate
  );
  const navigate = useNavigate();

  useEffect(() => {
    // if (subscriptionStatus === null) {
    //   return;
    // }

    // setCurrentPlan(plan);
    // setBalance({ current: credits, maxPossible: planCredits });

    /**----only for demo----- */
    setBalance({ current: credits });
    setCurrentPlan('-');
    setExpirationDate('-');
    setPaymentDate('-');

    // const date = formatDate(subscriptionCreatedDate);
    // setPaymentDate(date);

    // const expirationdate = formatDate(subscriptionExpirationDate);
    // setExpirationDate(expirationdate);

    // let currentPercent = null;

    // if (planCredits === null) {
    //   currentPercent = 100;
    // } else {
    //   currentPercent = (credits * 100) / planCredits;
    // }

    // setUsagePercentage(currentPercent);
  }, [
    credits,
    plan,
    planCredits,
    subscriptionCreatedDate,
    subscriptionExpirationDate,
    subscriptionStatus,
  ]);

  function handleUpgradePlan(e) {
    e.stopPropagation();
    navigate('pricing');
    closeModal();
  }

  // function formatDate(date) {
  //   const dateTime = new Date(date);
  //   const year = dateTime.getFullYear();
  //   const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  //   const day = dateTime.getDate();

  //   const formattedDate = `${day}.${month}.${year}`;

  //   return formattedDate;
  // }

  return (
    <>
      <CreditsWrap>
        {/* {balance.maxPossible === null ? (
          <CreditsText>unlimited credits</CreditsText>
        ) : (
          <CreditsText>
            {balance.current}/{balance.maxPossible} left
          </CreditsText>
        )}
        <Progress $used={usagePercentage}></Progress> */}
        <CreditsText>{balance.current} credits left</CreditsText>
      </CreditsWrap>

      <PlanDescription>
        <DescriptionItem>
          <ItemTitle>Current plan name</ItemTitle>
          <p>{currentPlan}</p>
        </DescriptionItem>
        <DescriptionItem>
          <ItemTitle>Current plan expiration date</ItemTitle>
          <p>{expirationDate}</p>
        </DescriptionItem>
        <DescriptionItem>
          <ItemTitle>Last payment date</ItemTitle>
          <p>{paymentDate}</p>
        </DescriptionItem>
      </PlanDescription>

      <ButtonByContent
        gbColor={'primary_color'}
        textColor={'light'}
        handleClick={handleUpgradePlan}
      >
        <ButtonText>upgrade plan</ButtonText>
      </ButtonByContent>
    </>
  );
};

export default Balance;
