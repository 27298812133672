export const vars = {
  darkBlack: 'rgba(0, 0, 0, 1)',
  darkBlack100: 'rgba(21, 21, 21, 1)',
  dark5: 'rgba(234, 234, 234, 1)',
  dark30: 'rgba(184, 180, 180,1)',
  dark70: 'rgba(89, 89, 89, 1)',
  darkTransparent70: 'rgba(21, 21, 21, 0.7)',
  darkTransparent20: 'rgba(21, 21, 21, 0.2)',
  darkTransparent5: 'rgba(21, 21, 21, 0.05)',

  light100: 'rgba(255, 255, 255, 1)',
  light50: 'rgba(247, 247, 247, 1)',
  lightTransparent70: 'rgba(255, 255, 255, 0.7)',
  lightTransparent30: 'rgba(255, 255, 255, 0.3)',

  primaryRose: 'rgba(242, 202, 217, 1)',
  primaryHover: 'rgba(186, 132, 166, 1)',
  primaryHover30: 'rgba(186, 132, 166, 0.3)',
  primaryPrimary: 'rgba(210, 151, 188, 1)',

  secondaryBlue: 'rgba(71, 90, 255, 1)',
  secondaryDarkBlue: 'rgba(151, 164, 210, 1)',
  secondaryMediumBlue: 'rgba(184, 212, 232, 1)',
  secondaryLightBlue: 'rgba(211, 236, 254, 1)',
  secondaryDarkBlueHover: 'rgba(122, 136, 183, 1)',
  secondaryBlueHover: 'rgba(46, 63, 215, 1)',

  secondaryViolet: 'rgba(236, 214, 255, 1)',
  secondaryVioletDark: 'rgba(219, 199, 235, 1)',

  secondaryGreen: 'rgba(75, 174, 79, 1)',
  secondaryGreenDark: 'rgba(76, 153, 79,1)',

  secondaryRed: 'rgba(237, 92, 118, 1)',
  secondaryMediumRed: 'rgba(255, 112, 112, 1)',

  modalBackgdrop: 'rgba(0, 0, 0, 0.7)',
  modalBackgdropLight: 'rgba(0, 0, 0, 0.10)',
  imageBackgdrop: 'rgba(0, 0, 0, 0.9)',
  modalBoxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.1)',

  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)',
  textShadow: '0px 0px 9.8px rgba(0, 0, 0, 0.12)',
  transparentBackground: function () {
    return `linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.7) 25%,
      transparent 25%
    ),
    linear-gradient(-45deg, rgba(255, 255, 255, 0.7) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(255, 255, 255, 0.7) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(255, 255, 255, 0.7) 75%)`;
  },

  mainBorderRadius: '24px',
  borderRadiusSmall: '16px',
  dropdownBorderRadius: '0px 0px 12px 12px',

  transitionTimingFunction: '400ms cubic-bezier(0.1, 0, 0.2, 1)',
};
