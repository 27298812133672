import { useState, useEffect } from 'react';

import icon from 'media/images/svgSprite.svg';
import UserHeader from 'components/user/UserHeader';
import UserCabinetSection from 'components/user/UserCabinetSection';
import ApiKeys from 'components/user/ApiKeys';
import Balance from 'components/user/Balance';
// import Statistics from 'components/user/Statistics';

import { Container } from 'components/App.styled';
import {
  CabinetContainer,
  UserCabinetWrap,
  IconClose,
  UserHeaderWrap,
  SectionBody,
} from 'components/user/UserCabinet/UserCabinet.styled';

const UserCabinet = ({ closeModal }) => {
  const [currentActive, setCurrentActive] = useState(1);

  useEffect(() => {
    let links = [];
    let headerElem = document.getElementById('header');

    if (headerElem) {
      links = headerElem.querySelectorAll('a');
    }

    if (links.length > 0) {
      [...links].forEach(item => {
        item.addEventListener('click', closeModal);
      });
    }

    return () => {
      [...links].forEach(item => {
        item.removeEventListener('click', closeModal);
      });
    };
  }, [closeModal]);

  function onOpenSection(newActiveSection) {
    setCurrentActive(
      newActiveSection === currentActive ? null : newActiveSection
    );
  }

  return (
    <Container>
      <CabinetContainer>
        <UserCabinetWrap>
          <IconClose onClick={closeModal}>
            <use href={`${icon}#icon-close`}></use>
          </IconClose>

          <UserHeaderWrap>
            <UserHeader pageType={'user-cabinet'} />
          </UserHeaderWrap>

          <ul>
            <UserCabinetSection
              title={'API Keys'}
              isActive={currentActive === 1}
              onOpenSection={() => onOpenSection(1)}
            >
              <SectionBody $active={currentActive === 1 ? 'true' : null}>
                <ApiKeys />
              </SectionBody>
            </UserCabinetSection>

            <UserCabinetSection
              title={'Balance'}
              isActive={currentActive === 2}
              onOpenSection={() => onOpenSection(2)}
            >
              <SectionBody $active={currentActive === 2 ? 'true' : null}>
                <Balance closeModal={closeModal} />
              </SectionBody>
            </UserCabinetSection>

            {/* <UserCabinetSection
              title={'Statistics'}
              isActive={currentActive === 3}
              onOpenSection={() => onOpenSection(3)}
            >
              <SectionBody $active={currentActive === 3 ? 'true' : null}>
                <Statistics />
              </SectionBody>
            </UserCabinetSection> */}
          </ul>
        </UserCabinetWrap>
      </CabinetContainer>
    </Container>
  );
};

export default UserCabinet;
