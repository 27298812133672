import icon from 'media/images/svgSprite.svg';

import {
  SectionWrap,
  ContentWrap,
  ArrowIcon,
  SectionTitle,
} from 'components/user/UserCabinetSection/UserCabinetSection.styled';

const UserCabinetSection = ({ onOpenSection, isActive, title, children }) => {
  return (
    <SectionWrap $active={isActive ? 'true' : null}>
      <ContentWrap onClick={onOpenSection}>
        <SectionTitle>{title}</SectionTitle>
        <ArrowIcon $active={isActive ? 'true' : null}>
          <use href={`${icon}#icon-arrow-cards`}></use>
        </ArrowIcon>
      </ContentWrap>
      {children}
    </SectionWrap>
  );
};

export default UserCabinetSection;
