import {
  LableWrap,
  LableText,
} from 'components/common/InDevLable/InDevLable.styled';

const InDevLable = () => {
  return (
    <LableWrap>
      <LableText>in progress</LableText>
    </LableWrap>
  );
};

export default InDevLable;
