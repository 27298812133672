import { useEffect, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getUserData } from '../../redux/user-data/operations';
import { resetUserData } from '../../redux/user-data/userDataSlice';
import { selectIntercomHash } from '../../redux/user-data/selectors';

import Header from 'components/SharedLayout/Header/Header';
import Footer from 'components/SharedLayout/Footer/Footer';
import Loader from 'components/common/Loader';
import SupportChat from 'components/SupportChat';

import { PageBody } from 'components/SharedLayout/SharedLayout.styled';

const SharedLayout = () => {
  const dispatch = useDispatch();
  const userHash = useSelector(selectIntercomHash);

  useEffect(() => {
    dispatch(getUserData());

    return () => {
      dispatch(resetUserData());
    };
  }, [dispatch]);

  return (
    <>
      <Header />

      <PageBody>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
        <Footer />
        {userHash && userHash !== '' && <SupportChat />}
      </PageBody>
    </>
  );
};

export default SharedLayout;
