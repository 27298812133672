import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectApiKey } from '../../../redux/user-data/selectors';

import useWindowWidth from 'hooks/useWindowWidth';

import Divider from 'components/common/Divider';
import {
  PriceButton,
  ButtonByContent,
  IconButtonByContent,
} from 'components/common/Buttons/Buttons';
import icon from 'media/images/svgSprite.svg';

import {
  AddButtonWrap,
  KeyItem,
  KeyWrap,
  KeyValue,
  ControlButton,
  CopyIcon,
} from 'components/user/ApiKeys/ApiKeys.styled';

const ApiKeys = () => {
  const [apiKey, setApiKey] = useState('');
  const userApiKey = useSelector(selectApiKey);

  const width = useWindowWidth();
  const isMobile = width < 767;

  useEffect(() => {
    if (userApiKey) {
      setApiKey(userApiKey);
    }
  }, [userApiKey]);

  function handleCreateKey(e) {
    e.stopPropagation();

    if (apiKey) return;
  }

  function handleCopyKey(e) {
    e.stopPropagation();

    navigator.clipboard.writeText(apiKey);
  }

  function handleDeleteKey(e) {
    e.stopPropagation();

    setApiKey(null);
  }

  return (
    <>
      <AddButtonWrap>
        <ButtonByContent
          gbColor={'secondary_color'}
          textColor={'light'}
          handleClick={handleCreateKey}
        >
          + API Key
        </ButtonByContent>
      </AddButtonWrap>

      <Divider />

      {apiKey && (
        <KeyItem>
          <h5>Key</h5>
          <KeyWrap>
            {apiKey && isMobile && (
              <KeyValue>
                <span>{apiKey}</span> ...
              </KeyValue>
            )}
            {apiKey && !isMobile && <KeyValue>{apiKey}</KeyValue>}

            <IconButtonByContent handleClick={handleCopyKey}>
              <CopyIcon>
                <use href={`${icon}#icon-copy`}></use>
              </CopyIcon>
            </IconButtonByContent>
          </KeyWrap>

          <ControlButton>
            <PriceButton handleClick={handleDeleteKey}>Delete</PriceButton>
          </ControlButton>
        </KeyItem>
      )}
    </>
  );
};

export default ApiKeys;
