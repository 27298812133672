import styled, { keyframes, css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { vars } from 'utils/variables';

const openMenu = keyframes`
  0% {
    transform: translateX(-100%);
    opacity:  0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
`;

const closeMenu = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const openOrClose = {
  open: css`300ms ${openMenu} ease-in-out`,
  close: css`300ms ${closeMenu} ease-in-out`,
};

const fadeIn = keyframes`
  0% {
    opacity:  0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity:  1;
  }
  100% {
    opacity: 0;
  }
`;

const fadeInOut = {
  open: css`300ms ${fadeIn} ease-in-out`,
  close: css`300ms ${fadeOut} ease-in-out`,
};

export const BackDrop = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  background-color: ${vars.modalBackgdropLight};
  backdrop-filter: blur(9.75px);
  opacity: ${props => props.$opacity};
  animation: ${props => props.$isOpenClose && fadeInOut[props.$isOpenClose]};
  z-index: 2147483002;
`;

export const MenuWrap = styled.section`
  height: 100vh;
  /* Using dvh to support dynamic height on new browsers */
  height: 100dvh;

  padding: 9px 40px;

  position: relative;

  display: inline-block;
  background-color: ${vars.light100};

  transform: translateX(0);
  opacity: ${props => props.$opacity};

  transition: width ${vars.transitionTimingFunction};
  animation: ${props => props.$isOpenClose && openOrClose[props.$isOpenClose]};
  animation-play-state: running;
  animation-timing-function: linear;
`;

export const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 51px;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${vars.light100};
  border-radius: 100px;
  transform: translateX(50%);
  transition: background-color ${vars.transitionTimingFunction};

  &:hover {
    background-color: ${vars.light50};
  }

  &:hover > svg,
  &:active > svg {
    fill: ${vars.primaryHover};
  }
`;

export const IconClose = styled.svg`
  width: 16px;
  height: 16px;

  fill: ${vars.dark70};

  transition: fill ${vars.transitionTimingFunction};
`;

export const MenuLogoWrap = styled.div`
  width: 143px;
  height: 49px;

  padding: 0;

  position: relative;
`;

//-------------Tools-----------------

export const ToolList = styled.ul`
  margin-top: 30px;
  margin-bottom: 12px;

  display: flex;
  flex-direction: column;

  pointer-events: all;
`;

export const Tool = styled.li`
  margin-bottom: 8px;
`;

export const ElemLink = styled(NavLink)`
  height: 100%;

  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  &:hover > section > p,
  &:active > section > p {
    color: ${vars.primaryHover};
  }

  &:hover > div,
  &:active > div {
    background-color: ${vars.primaryHover};
  }
`;

export const NavIconWrap = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${vars.dark70};
  border-radius: 107px;

  transition: background-color ${vars.transitionTimingFunction};
`;

export const ToolsIcon = styled.svg`
  width: 16px;
  height: 16px;

  fill: ${vars.light100};
  transition: fill ${vars.transitionTimingFunction};
`;

export const TitleWrap = styled.section`
  position: relative;
`;

export const ToolsTitle = styled.p`
  color: ${vars.dark70};
  transition: color ${vars.transitionTimingFunction};

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
`;

export const BottomSection = styled.section`
  width: 100px;
  height: 74px;

  position: fixed;
  /* Fallback for older browsers */
  bottom: 100px;
  /* for new browsers that support safe-area-inset (e.g. Safari) */
  bottom: calc(40px + env(safe-area-inset-bottom, 60px));
  /* Using dvh to support dynamic height on new browsers */
  bottom: calc(100dvh - (100dvh - 40px));
  left: 40px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 768px) {
    bottom: 40px;
  }
`;

export const LogoutButtonWrap = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;

  background-color: transparent;

  &:hover > section,
  &:active > section {
    background-color: ${vars.primaryHover};
  }

  &:hover > p,
  &:active > p {
    color: ${vars.primaryHover};
  }
`;

export const LogoutButton = styled.section`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${vars.dark70};
  border-radius: 107px;

  transition: background-color ${vars.transitionTimingFunction};
`;
