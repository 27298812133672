import { createPortal } from 'react-dom';
import { useEffect } from 'react';

import { BackDropLight } from 'components/common/modals/ModalLight/ModalLight.styled';

const modalWindow = document.getElementById('modal-light');

const ModalLight = ({ closeModal, children }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const handlePressESC = e => {
      if (e.code === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handlePressESC);

    return () => {
      document.body.style.overflow = 'unset';
      window.removeEventListener('keydown', handlePressESC);
    };
  }, [closeModal]);

  function handleClickOnBackDrop(e) {
    if (e.target.nodeName === 'DIV' && e.currentTarget.nodeName === 'DIV') {
      closeModal();
    }
  }

  return createPortal(
    <BackDropLight onClick={handleClickOnBackDrop}>{children}</BackDropLight>,
    modalWindow
  );
};

export default ModalLight;
