import Intercom from '@intercom/messenger-js-sdk';
import { update } from '@intercom/messenger-js-sdk';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectUserId,
  selectFirstName,
  selectIntercomHash,
} from '../../redux/user-data/selectors';
import { INTERCOM_APP_ID } from 'utils/configs';

const SupportChat = () => {
  const userId = useSelector(selectUserId);
  const userName = useSelector(selectFirstName);
  const userHash = useSelector(selectIntercomHash);

  useEffect(() => {
    Intercom({
      region: 'us',
      api_base: 'https://api-iam.intercom.io',
      app_id: INTERCOM_APP_ID,
      user_id: userId,
      name: userName || '',
      user_hash: userHash,
    });
  }, [userHash, userId, userName]);

  useEffect(() => {
    update();
  }, []);

  return <></>;
};

export default SupportChat;
