import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

/**----------reducers----------- */
import { aiPhotoshootingReducer } from './ai-photoshooting/aiPhotoshootingSlice';
import { aiGenerateModelsReducer } from './ai-generate-models/aiGenerateModelsSlice';
import { bgRemoveReducer } from './bg-remove/bgRemoveSlice';
import { imgUpscalingReducer } from './image-upscaling/imageUpscalingSlice';
import { fashionStoreReducer } from './fashion-store/fashionStoreSlice';
import { userDataReducer } from './user-data/userDataSlice';

/**----------persist configs----------- */
const aiphotoshootingPersistConfig = {
  key: 'aiphotoshooting',
  storage,
  whitelist: ['models'],
};

const aigeneratemodelsPersistConfig = {
  key: 'aigeneratemodels',
  storage,
  whitelist: ['ai_generate_models'],
};

const bgremovePersistConfig = {
  key: 'bgremove',
  storage,
  whitelist: [],
};

const imageUpscalingPersistConfig = {
  key: 'imageUpscaling',
  storage,
  whitelist: [],
};

const fashionStorePersistConfig = {
  key: 'fashionStore',
  storage,
  whitelist: [],
};

const userDataPersistConfig = {
  key: 'userData',
  storage,
  whitelist: [],
};

/**----------configure store----------- */
export const store = configureStore({
  reducer: {
    aiphotoshooting: persistReducer(
      aiphotoshootingPersistConfig,
      aiPhotoshootingReducer
    ),
    aigeneratemodels: persistReducer(
      aigeneratemodelsPersistConfig,
      aiGenerateModelsReducer
    ),
    bgremove: persistReducer(bgremovePersistConfig, bgRemoveReducer),
    imageupscaling: persistReducer(
      imageUpscalingPersistConfig,
      imgUpscalingReducer
    ),
    fashionstore: persistReducer(
      fashionStorePersistConfig,
      fashionStoreReducer
    ),
    userdata: persistReducer(userDataPersistConfig, userDataReducer),
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'fashionstore/fsSetOwnFilesForOrder',
        ],
        ignoredPaths: ['fashionstore'],
      },
    }),
});

export const persistor = persistStore(store);
