import { createSlice } from '@reduxjs/toolkit';

import {
  getAiGenerateModelsAvatars,
  getAiGenerateModelsTaskList,
  goToTheAiGenerateModelsTask,
  removeAiGenerateModelsTask,
} from './operations';

import { SIZES } from 'utils/constants';
import { TESTTASK_ID, TEST_IMAGE_ID } from 'utils/configs';

import defaultInputImage from 'media/images/test_ai_model_input.jpg';
import defaultResultImage from 'media/images/test_ai_model_result.jpg';

const defaultTask = {
  id: TESTTASK_ID,
  avatar: { image: 'girl1.jpeg' },
  images: [
    {
      id: TEST_IMAGE_ID,
      input_image: defaultInputImage,
      result_image: defaultResultImage,
    },
  ],
  status: 'test',
};

let aiGenerateModelsInitialState = {
  models: [],
  taskList: [],
  taskDetails: null,
  taskStatus: null,
  filters: {
    size: `${SIZES.medium}`,
    gender: 'woman',
    cloth_type: 'upper_body',
  },
  isLoading: false,
  responseErrorMessage: null,
};

const handlePending = state => {
  state.responseErrorMessage = null;
  state.isLoading = true;
};

const handleRejected = (state, { payload }) => {
  state.responseErrorMessage = payload;
  state.isLoading = false;
};

export const aiGenerateModelsSlice = createSlice({
  name: 'aigeneratemodels',
  initialState: aiGenerateModelsInitialState,

  reducers: {
    setFilterValue(state, { payload }) {
      state.filters = { ...state.filters, ...payload };
    },

    setDefaultTaskDetails(state) {
      state.taskDetails = defaultTask;
      state.taskStatus = 'test';
    },

    resetTaskDetails(state) {
      state.taskDetails = null;
      state.taskStatus = null;
    },

    resetTaskList(state) {
      state.taskList = [];
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getAiGenerateModelsAvatars.pending, handlePending)
      .addCase(getAiGenerateModelsAvatars.rejected, handleRejected)
      .addCase(getAiGenerateModelsAvatars.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.models = payload;
        state.responseErrorMessage = null;
      })
      .addCase(getAiGenerateModelsTaskList.pending, handlePending)
      .addCase(getAiGenerateModelsTaskList.rejected, handleRejected)
      .addCase(getAiGenerateModelsTaskList.fulfilled, (state, { payload }) => {
        if (payload.length > 0) {
          state.taskList = payload;
        } else {
          state.taskList = [defaultTask];
        }
        state.isLoading = false;
        state.responseErrorMessage = null;
      })
      .addCase(goToTheAiGenerateModelsTask.pending, handlePending)
      .addCase(goToTheAiGenerateModelsTask.rejected, handleRejected)
      .addCase(goToTheAiGenerateModelsTask.fulfilled, (state, { payload }) => {
        state.taskDetails = payload;
        state.taskStatus = payload.status;
        state.isLoading = false;
        state.responseErrorMessage = null;
      })
      .addCase(removeAiGenerateModelsTask.pending, handlePending)
      .addCase(removeAiGenerateModelsTask.rejected, handleRejected)
      .addCase(removeAiGenerateModelsTask.fulfilled, (state, { payload }) => {
        state.taskList = state.taskList.filter(task => task.id !== payload);
        state.isLoading = false;
        state.responseErrorMessage = null;
      });
  },
});

export const {
  setFilterValue,
  setDefaultTaskDetails,
  resetTaskDetails,
  resetTaskList,
} = aiGenerateModelsSlice.actions;

export const aiGenerateModelsReducer = aiGenerateModelsSlice.reducer;
