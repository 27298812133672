import styled from 'styled-components';
import { vars } from 'utils/variables';

export const LableWrap = styled.div`
  height: 15px;
  padding: 2px 4px;

  position: absolute;
  top: -16px;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background-color: ${vars.dark30};
`;

export const LableText = styled.p`
  color: ${vars.light100};

  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
`;
