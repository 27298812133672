import styled from 'styled-components';
export const Container = styled.div`
  max-width: 1200px;
  min-height: 250px;
  width: 100%;
  height: 100%;

  padding-top: 70px;
  padding-bottom: 0px;
  padding-left: 16px;
  padding-right: 16px;

  margin: 0 auto;

  flex-grow: 1;

  @media screen and (min-width: 768px) {
    padding-left: 28px;
    padding-right: 28px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media screen and (min-width: 1200px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;
