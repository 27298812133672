import styled, { keyframes } from 'styled-components';
import { vars } from 'utils/variables';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${vars.modalBackgdrop};
  animation: 200ms ${fadeIn} ease;
  z-index: 10000;
`;
