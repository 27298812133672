import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  IMG_UPSCALING__BASE_PATH,
  API__PREFIX,
  API__VERSION,
} from 'utils/configs';

import handleError from 'services/handleError';
import getTaskList from 'services/getTaskList';
import goToTheTask from 'services/goToTheTask';
import deleteTask from 'services/deleteTask';

axios.defaults.withCredentials = true;

export const getImgUpscalingTaskList = createAsyncThunk(
  'imageUpscaling/gallery',

  async (_, thunkAPI) => {
    try {
      const data = getTaskList(IMG_UPSCALING__BASE_PATH, thunkAPI);

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const goToTheImgUpscalingTask = createAsyncThunk(
  'imageUpscaling/goToTheImgUpscalingTask',

  async (taskId, thunkAPI) => {
    try {
      const data = goToTheTask(IMG_UPSCALING__BASE_PATH, taskId, thunkAPI);

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const removeImgUpscalingTask = createAsyncThunk(
  'imageUpscaling/removeTask',

  async (taskId, thunkAPI) => {
    try {
      const data = deleteTask(IMG_UPSCALING__BASE_PATH, taskId, thunkAPI);

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

//------------------

export const createImgUpscalingTaskFromGallery = createAsyncThunk(
  'imageUpscaling/createFromGallery',

  async (taskData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${API__PREFIX}${API__VERSION}${IMG_UPSCALING__BASE_PATH}/task/create-from-gallery`,
        taskData
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const createImgUpscalingTaskFromUploaded = createAsyncThunk(
  'imageUpscaling/createFromUploaded',

  async (taskData, thunkAPI) => {
    try {
      const formData = new FormData();

      taskData.forEach((item, index) => {
        formData.append('input_files', taskData[index]);
      });
      const { data } = await axios.post(
        `${API__PREFIX}${API__VERSION}${IMG_UPSCALING__BASE_PATH}/task/create-from-uploaded`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);
