import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  BASE_URL,
  AI_PHOTOSHOOTING__BASE_PATH,
  API__PREFIX,
  API__VERSION,
} from 'utils/configs';

import handleError from 'services/handleError';
import getTaskList from 'services/getTaskList';
import goToTheTask from 'services/goToTheTask';
import deleteTask from 'services/deleteTask';

axios.defaults.baseURL = `${BASE_URL}`;
axios.defaults.withCredentials = true;

export const getAvatars = createAsyncThunk(
  'aiphotoshooting/getAvatars',

  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${API__PREFIX}${API__VERSION}${AI_PHOTOSHOOTING__BASE_PATH}/avatar/query
        `
      );
      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const createTask = createAsyncThunk(
  'aiphotoshooting/create',

  async (taskData, thunkAPI) => {
    try {
      const formData = new FormData();

      Object.entries(taskData).forEach(([key, value]) => {
        if (key === 'avatar_id') {
          formData.append(key, value);
        } else {
          value.forEach((item, index) => {
            formData.append('input_files', value[index]);
          });
        }
      });

      const { data } = await axios.post(
        `${API__PREFIX}${API__VERSION}${AI_PHOTOSHOOTING__BASE_PATH}/task/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const createAiPhotoshootingTaskFromGallery = createAsyncThunk(
  'aiphotoshooting/createFromGallery',

  async (taskData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${API__PREFIX}${API__VERSION}${AI_PHOTOSHOOTING__BASE_PATH}/task/create-from-gallery`,
        taskData
      );

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const getAiPhotoshootingTaskList = createAsyncThunk(
  'aiphotoshooting/gallery',

  async (_, thunkAPI) => {
    try {
      const data = getTaskList(AI_PHOTOSHOOTING__BASE_PATH, thunkAPI);

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const goToTheAiPhotoshootingTask = createAsyncThunk(
  'aiphotoshooting/goToTheTask',

  async (taskId, thunkAPI) => {
    try {
      const data = goToTheTask(AI_PHOTOSHOOTING__BASE_PATH, taskId, thunkAPI);

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);

export const removeTask = createAsyncThunk(
  'aiphotoshooting/removeTask',

  async (taskId, thunkAPI) => {
    try {
      const data = deleteTask(AI_PHOTOSHOOTING__BASE_PATH, taskId, thunkAPI);

      return data;
    } catch (error) {
      const { status, data } = error.response;
      const errorDetails = handleError(status, data);

      return thunkAPI.rejectWithValue(errorDetails);
    }
  }
);
