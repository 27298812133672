import { useAuthenticator } from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const SignInUp = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { user } = useAuthenticator(context => [context.user]);

  useEffect(() => {
    if (user) {
      setIsAuthorized(true);
    }
  }, [user]);

  return <>{isAuthorized && <Navigate to="/" />}</>;
};

export default SignInUp;
