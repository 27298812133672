import axios from 'axios';
import handleError from 'services/handleError';
import { API__PREFIX, API__VERSION } from 'utils/configs';

const getTaskList = async (toolPath, thunkAPI) => {
  let taskType = '';
  if (toolPath !== `/ai-fashion-store/order`) {
    taskType = '/task';
  }

  try {
    const { data } = await axios.get(
      `${API__PREFIX}${API__VERSION}${toolPath}${taskType}/query`
    );

    return data;
  } catch (error) {
    const { status, data } = error.response;
    const errorDetails = handleError(status, data);

    return thunkAPI.rejectWithValue(errorDetails);
  }
};

export default getTaskList;
