import axios from 'axios';
import handleError from 'services/handleError';
import { API__PREFIX, API__VERSION } from 'utils/configs';

const goToTheTask = async (toolPath, taskId, thunkAPI) => {
  try {
    const { data } = await axios.get(
      `${API__PREFIX}${API__VERSION}${toolPath}/task/${taskId}/read`
    );

    return data;
  } catch (error) {
    const { status, data } = error.response;
    const errorDetails = handleError(status, data);

    return thunkAPI.rejectWithValue(errorDetails);
  }
};

export default goToTheTask;
