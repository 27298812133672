import styled from 'styled-components';
import { vars } from 'utils/variables';

export const BetaLableWrap = styled.button`
  width: ${props => (props.$lableLocation === 'menu' ? '36px' : '26px')};
  padding: 4px;

  position: absolute;
  top: ${props => props.$top};
  right: ${props => props.$right};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${vars.primaryPrimary};
  border-radius: 16px;
  border: none;
  outline: none;
  cursor: default;

  &:hover + section,
  &:focus + section {
    padding: 12px 16px;
    opacity: 1;
    & p {
      height: auto;
    }
  }

  @media screen and (min-width: 768px) {
    width: 36px;
    right: -42px;
  }
`;

export const BetaIcon = styled.svg`
  width: ${props => (props.$lableLocation === 'menu' ? '20px' : '18px')};
  height: ${props => (props.$lableLocation === 'menu' ? '6px' : '5px')};

  line-height: normal;

  @media screen and (min-width: 768px) {
    width: 20px;
    height: 6px;
  }
`;

export const BetaMessage = styled.section`
  width: 100vw;
  padding: 0;
  position: absolute;
  top: ${props => props.$top};
  bottom: ${props => props.$bottom};
  left: ${props => props.$left};

  background-color: ${vars.dark70};
  box-shadow: ${vars.boxShadow};
  opacity: 0;
  cursor: default;
  transform: ${props => `translateX(${props.$translateX})`};

  transition: opacity ${vars.transitionTimingFunction};

  &:hover {
    opacity: 0;
  }

  & > p {
    height: 0px;
    font-family: Poppins;
    font-size: 11px;
    line-height: normal;
    text-align: start;
    color: ${vars.lightTransparent70};
  }

  @media screen and (min-width: 768px) {
    width: 400px;
    left: 0;
    border-radius: 24px;
    transform: translateX(0);
  }
`;
