import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { BackDrop } from 'components/common/modals/Modal/Modal.styled';

const modalWindow = document.getElementById('modal');

const Modal = ({ closeModal, children }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const handlePressESC = e => {
      if (e.code === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handlePressESC);

    return () => {
      document.body.style.overflow = 'unset';
      window.removeEventListener('keydown', handlePressESC);
    };
  }, [closeModal]);

  const handleBackDropClick = e => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return createPortal(
    <BackDrop onClick={handleBackDropClick}>{children}</BackDrop>,
    modalWindow
  );
};

export default Modal;
