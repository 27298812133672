import styled from 'styled-components';
import { vars } from 'utils/variables';

export const AddButtonWrap = styled.section`
  margin-bottom: 24px;
`;

export const KeyItem = styled.section`
  width: 100%;

  padding: 0;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  @media screen and (min-width: 768px) {
    height: 48px;
    padding: 0 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const KeyWrap = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;

  @media screen and (min-width: 768px) {
    width: auto;
  }
`;

export const KeyValue = styled.p`
  width: calc(100% - 22px);
  max-width: 356px;
  height: 28px;
  padding: 4px 12px;

  background-color: ${vars.light50};
  color: ${vars.dark70};
  border-radius: 16px;

  & > span {
    display: inline-block;
    width: calc(100% - 10px);
    overflow: hidden;
  }

  @media screen and (min-width: 768px) {
    width: auto;
    max-width: 100%;
  }
`;

export const ControlButton = styled.section`
  width: 80px;
  height: 32px;

  padding: 0;
`;

export const CopyIcon = styled.svg`
  width: 16px;
  height: 16px;
  fill: ${vars.secondaryBlue};
  cursor: pointer;

  &:active {
    fill: ${vars.secondaryGreenDark};
  }
`;
