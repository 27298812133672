import { signOut } from 'aws-amplify/auth';

import { MODAL_META } from 'utils/constants';

const handleError = (status, errorData) => {
  const intercomObj = document.getElementById('intercom-frame');
  const handledStatusCodes = [400, 401, 403];
  let errorInfo = { message: '', errorType: '', details: null };

  if (status === 401 || status === 403) {
    if (intercomObj) {
      window.Intercom('shutdown');
    }

    signOut();
  }

  if (status === 400) {
    switch (errorData.type) {
      case 'notEnoughCredits':
        errorInfo.message = errorData.detail;
        errorInfo.errorType = errorData.type;
        break;

      case 'validationError':
        errorInfo.message = `${MODAL_META.error.imageError}`;
        errorInfo.details = errorData.data.errors;
        errorInfo.errorType = errorData.type;
        break;

      default:
        errorInfo.message = `${MODAL_META.error.message}`;
        errorInfo.errorType = errorData.type;
    }
  }

  if (!handledStatusCodes.includes(status)) {
    errorInfo.message = `${MODAL_META.error.message}`;
    errorInfo.errorType = errorData.type || 'unknown';
  }

  return errorInfo;
};

export default handleError;
